// @ts-ignore
import Velocity from 'velocity-animate';
;
export default class Animation {
    constructor(configuration) {
        this._complete = false;
        this.animationStart = configuration.startPosition || [0, 0];
        this.animationDuration = configuration.duration || 500;
        this.yTriggerPoint = configuration.yTriggerPoint || 0;
        this._target = configuration.target || null;
        this._transition = configuration.transition || 'easeInOutQuad';
        this._delay = configuration.delay || 0;
        if (this._target == null)
            return;
        this._target.style.opacity = '0';
        window.addEventListener('scroll', () => this._animate());
        window.addEventListener('resize', () => this._animate());
        document.addEventListener('DOMContentLoaded', async () => {
            await new Promise(resolve => setTimeout(resolve, 500));
            this._animate();
        });
    }
    ;
    async _animate() {
        if (this._complete)
            return;
        // if (this._target.getBoundingClientRect().top > (window.innerHeight - this.yTriggerPoint) || this._target.getBoundingClientRect().bottom < 0) return;
        if (this._target.getBoundingClientRect().top > (window.innerHeight - this.yTriggerPoint))
            return;
        this._complete = true;
        await Velocity(this._target, {
            'translateX': this.animationStart[0],
            'translateY': this.animationStart[1],
        }, {
            'duration': 0.5
        });
        await new Promise(resolve => setTimeout(resolve, this._delay));
        await Velocity(this._target, {
            'translateX': 0,
            'translateY': 0,
            'opacity': 1,
        }, {
            'duration': this.animationDuration,
            'easing': this._transition,
        });
    }
    ;
    static create(animations) {
        animations.forEach(animation => {
            animation.ids.forEach(id => {
                const targets = document.querySelectorAll(`[data-animation="${id}"]`);
                targets.forEach(target => {
                    const config = Object.assign({}, animation);
                    config.target = target;
                    new Animation(config);
                });
            });
        });
    }
    ;
}
;
