export default class Navigation {
    constructor(configuration) {
        this._targets = [];
        this._idList = configuration.idList;
        this._initialCallback = configuration.initialCallback;
        this._dockedCallback = configuration.dockedCallback;
        this._undockedCallback = configuration.undockedCallback;
        this.initialise();
    }
    async initialise() {
        this._idList.forEach(id => this._targets.push(document.querySelector(`[data-nav="${id}"]`)));
        this._targets.forEach(async (target) => {
            if (target === null)
                return;
            await this._initialCallback(target);
            window.addEventListener('scroll', async () => {
                if (window.scrollY === 0) {
                    await this._docked(target);
                }
                else {
                    await this._undocked(target);
                }
            });
        });
    }
    ;
    async _docked(target) {
        await this._dockedCallback(target);
    }
    ;
    async _undocked(target) {
        await this._undockedCallback(target);
    }
    ;
}
