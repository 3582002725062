import Contact from '../class/Contact/Contact.js';
import Form from '../class/Form/Form.js';
export default class Router {
    static initialise() {
        Router.initialiseContact();
    }
    static initialiseContact() {
        new Contact(['first', 'last', 'email', 'company', 'date', 'time']);
        new Form({
            'id': '2',
            'route': '/data/free-vulnerability-assessment',
            'data': ['firstName', 'lastName', 'email', 'company', 'date', 'time'],
            'preRequestCallback': Router.preRequestCallback,
            'successCallback': Router.successCallback,
            'errorCallback': Router.errorCallback,
        });
    }
    static preRequestCallback(formData) {
        Router.errorMessage.innerText = '';
        Router.successMessage.innerText = '';
        const emailRegularExpression = /\S+@\S+\.\S+/;
        if ((String(formData.firstName) == null) || (String(formData.firstName) == '')) {
            Router.errorMessage.innerText = 'Please provide a first name.';
            return false;
        }
        if (!(emailRegularExpression.test(String(formData.email).toLowerCase()))) {
            Router.errorMessage.innerText = 'Please provide a valid email address.';
            return false;
        }
        Router.pendingMessage.innerText = 'Please wait, your message is being sent...';
        return true;
    }
    static successCallback() {
        Router.pendingMessage.innerText = '';
        Router.successMessage.innerText = 'Thank you! We will get in touch shortly regarding your scoping call.';
        // @ts-ignore
        uet_report_conversion();
    }
    static errorCallback(response) {
        Router.pendingMessage.innerText = '';
        Router.errorMessage.innerText = response.error;
    }
}
Router.errorMessage = document.querySelector('[data-form="error"]');
Router.successMessage = document.querySelector('[data-form="success"]');
Router.pendingMessage = document.querySelector('[data-form="pending"]');
;
