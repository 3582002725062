export default class Dropdown {
    constructor(configuration) {
        this._targets = [];
        this._idList = configuration.idList;
        this._initialCallback = configuration.initialCallback;
        this._showCallback = configuration.showCallback;
        this._hideCallback = configuration.hideCallback;
        this.initialise();
    }
    async initialise() {
        this._idList.forEach(id => this._targets.push(document.querySelector(`[data-cfclickdropdown="${id}"]`)));
        this._targets.forEach(async (target) => {
            if (target === null)
                return;
            const id = target.getAttribute('data-cfclickdropdown');
            const dropdown = document.querySelector(`[data-cfdropdowncontent="${id}"]`);
            let additional = await this._initialCallback(target, dropdown);
            target.addEventListener('click', () => { this._showDropdown(target, dropdown, additional); }, { 'once': true });
        });
    }
    ;
    async _showDropdown(target, dropdown, additional) {
        additional = await this._showCallback(target, dropdown, additional);
        target.addEventListener('click', () => { this._hideDropdown(target, dropdown, additional); }, { 'once': true });
    }
    ;
    async _hideDropdown(target, dropdown, additional) {
        additional = await this._hideCallback(target, dropdown, additional);
        target.addEventListener('click', () => { this._showDropdown(target, dropdown, additional); }, { 'once': true });
    }
    ;
}
