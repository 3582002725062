export default class Contact {
    constructor(fieldIds) {
        this._fields = [];
        fieldIds.forEach(fieldId => {
            this._fields.push(document.querySelector(`[data-contactfield="${fieldId}"]`));
        });
        this._createListeners();
    }
    _createListeners() {
        this._fields.forEach(field => {
            const label = field.parentElement.getElementsByTagName('label')[0];
            document.addEventListener('DOMContentLoaded', () => {
                if (field.value !== '') {
                    setTimeout(() => label.style.opacity = '1', 100);
                }
                else {
                    label.style.opacity = '0';
                }
            });
            field.addEventListener('keyup', () => {
                if (field.value !== '') {
                    setTimeout(() => label.style.opacity = '1', 100);
                }
                else {
                    label.style.opacity = '0';
                }
            });
            field.addEventListener('focusout', () => {
                if (field.value === '' && field.required) {
                    field.classList.add('error');
                }
                else {
                    field.classList.remove('error');
                }
            });
        });
    }
}
