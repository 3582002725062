export default class BaseConverter {
    constructor(initialValue, initialBase) {
        this._initialValue = initialValue || '';
        this._initialBase = initialBase || 10;
    }
    getBase(newBase) {
        const base10 = parseInt(this._initialValue, this._initialBase);
        return base10.toString(newBase);
    }
}
