import Dropdown from '../class/Dropdown/Dropdown.js';
import Navigation from '../class/Navigation/Navigation.js';
// import Cookie from '../class/Cookie/Cookie.js';
import Animation from '../class/Animation/Animation.js';
import Form from '../class/Form/Form.js';
import BaseConverter from '../class/Tools/BaseConverter/BaseConverter.js';
import AnimationWave from '../class/Animation/AnimationWave.js';
export default class Router {
    static initialise() {
        Router.initialiseDropdown();
        Router.initialiseNavigation();
        Router.initialiseMisc();
        Router.initialiseRequestAReport();
    }
    ;
    static initialiseDropdown() {
        new Dropdown({
            'idList': ['001', '002', '003', '004', '011', '012', '013', '014'],
            'showCallback': async (target, dropdown, additional) => {
                const cross = target.querySelector('[data-faqcross]');
                cross.style.transform = "rotate(45deg)";
                dropdown.style.display = 'block';
                await new Promise(resolve => setTimeout(resolve, 1));
                dropdown.style.maxHeight = `${additional.initialHeight + 100}px`;
                dropdown.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 500));
                if (dropdown.style.maxHeight !== '0px') {
                    dropdown.style.maxHeight = `${dropdown.offsetHeight}px`;
                    additional.initialHeight = dropdown.offsetHeight;
                }
                return additional;
            },
            'hideCallback': async (target, dropdown, additional) => {
                const cross = target.querySelector('[data-faqcross]');
                cross.style.transform = "rotate(0deg)";
                dropdown.style.opacity = '0';
                dropdown.style.maxHeight = '0px';
                await new Promise(resolve => setTimeout(resolve, 500));
                dropdown.style.display = 'none';
                return additional;
            },
            'initialCallback': (target, dropdown) => {
                const additional = {
                    'initialHeight': dropdown.offsetHeight,
                };
                dropdown.style.opacity = '0';
                dropdown.style.maxHeight = '0px';
                dropdown.style.display = 'none';
                return additional;
            },
        });
        new Dropdown({
            'idList': ['mobile_nav-dark'],
            'initialCallback': async (target, dropdown) => {
                const additional = {};
                dropdown.style.opacity = '0';
                dropdown.style.display = 'none';
                additional.nav = target.closest('[data-nav]');
                return additional;
            },
            'showCallback': async (target, dropdown, additional) => {
                dropdown.style.display = 'grid';
                await new Promise(resolve => setTimeout(resolve, 1));
                dropdown.style.opacity = '1';
                target.style.opacity = '0';
                additional.nav.style.backgroundColor = '#202528';
                additional.nav.style.borderBottomWidth = '1px';
                await new Promise(resolve => setTimeout(resolve, 200));
                target.src = '/img/icon/cta/cross-light.svg';
                target.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 200));
                if (document.documentElement.scrollHeight > window.innerHeight) {
                    const scrollTop = (document.documentElement.scrollTop) ? document.documentElement.scrollTop : document.body.scrollTop;
                    document.documentElement.classList.add('no_scroll');
                    additional.scrollTop = scrollTop;
                }
                return additional;
            },
            'hideCallback': async (target, dropdown, additional) => {
                dropdown.style.opacity = '0';
                target.style.opacity = '0';
                if (window.scrollY === 0) {
                    additional.nav.style.backgroundColor = 'transparent';
                    additional.nav.style.borderBottomWidth = '0px';
                }
                const scrollTop = additional.scrollTop;
                document.documentElement.classList.remove('no_scroll');
                document.documentElement.scrollTop = scrollTop;
                await new Promise(resolve => setTimeout(resolve, 200));
                target.src = '/img/icon/cta/hamburger-light.svg';
                target.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 200));
                dropdown.style.display = 'none';
                return additional;
            },
        });
        new Dropdown({
            'idList': ['mobile_nav-light'],
            'initialCallback': async (target, dropdown) => {
                const additional = {};
                dropdown.style.opacity = '0';
                dropdown.style.display = 'none';
                additional.nav = target.closest('[data-nav]');
                return additional;
            },
            'showCallback': async (target, dropdown, additional) => {
                dropdown.style.display = 'grid';
                await new Promise(resolve => setTimeout(resolve, 1));
                dropdown.style.opacity = '1';
                target.style.opacity = '0';
                additional.nav.style.backgroundColor = '#FFFFFF';
                additional.nav.style.borderBottomWidth = '1px';
                await new Promise(resolve => setTimeout(resolve, 200));
                target.src = '/img/icon/cta/cross-dark.svg';
                target.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 200));
                if (document.documentElement.scrollHeight > window.innerHeight) {
                    const scrollTop = (document.documentElement.scrollTop) ? document.documentElement.scrollTop : document.body.scrollTop;
                    document.documentElement.classList.add('no_scroll');
                    additional.scrollTop = scrollTop;
                }
                return additional;
            },
            'hideCallback': async (target, dropdown, additional) => {
                dropdown.style.opacity = '0';
                target.style.opacity = '0';
                if (window.scrollY === 0) {
                    additional.nav.style.backgroundColor = 'transparent';
                    additional.nav.style.borderBottomWidth = '0px';
                }
                const scrollTop = additional.scrollTop;
                document.documentElement.classList.remove('no_scroll');
                document.documentElement.scrollTop = scrollTop;
                await new Promise(resolve => setTimeout(resolve, 200));
                target.src = '/img/icon/cta/hamburger-dark.svg';
                target.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 200));
                dropdown.style.display = 'none';
                return additional;
            },
        });
        new Dropdown({
            'idList': ['footer-pages', 'footer-accreditation', 'footer-services', 'footer-legal'],
            'showCallback': async (target, dropdown, additional) => {
                const cross = target.querySelector('[data-dropdowncross]');
                cross.style.transform = "rotate(45deg)";
                dropdown.style.display = 'block';
                await new Promise(resolve => setTimeout(resolve, 1));
                dropdown.style.maxHeight = `${additional.initialHeight + 100}px`;
                dropdown.style.opacity = '1';
                await new Promise(resolve => setTimeout(resolve, 500));
                if (dropdown.style.maxHeight !== '0px') {
                    dropdown.style.maxHeight = `${dropdown.offsetHeight}px`;
                    additional.initialHeight = dropdown.offsetHeight;
                }
                return additional;
            },
            'hideCallback': async (target, dropdown, additional) => {
                const cross = target.querySelector('[data-dropdowncross]');
                cross.style.transform = "rotate(0deg)";
                dropdown.style.opacity = '0';
                dropdown.style.maxHeight = '0px';
                await new Promise(resolve => setTimeout(resolve, 500));
                dropdown.style.display = 'none';
                return additional;
            },
            'initialCallback': (target, dropdown) => {
                const initialHeight = (dropdown.offsetHeight == 0) ? 100 : dropdown.offsetHeight;
                const additional = {
                    'initialHeight': initialHeight,
                };
                dropdown.style.opacity = '0';
                dropdown.style.maxHeight = '0px';
                dropdown.style.display = 'none';
                return additional;
            },
        });
    }
    static initialiseNavigation() {
        new Navigation({
            'idList': ['desktop-dark', 'mobile-dark'],
            'initialCallback': (target) => {
                if (window.scrollY !== 0) {
                    target.style.backgroundColor = '#202528';
                    target.style.borderBottomWidth = '1px';
                    const button = target.querySelector('[data-consultation]');
                    if (button) {
                        button.style.backgroundColor = '#0273E9';
                        button.style.color = 'white';
                    }
                }
                else {
                    target.style.backgroundColor = 'transparent';
                    target.style.borderBottomWidth = '0px';
                    const button = target.querySelector('[data-consultation]');
                    if (button) {
                        button.style.backgroundColor = 'white';
                        button.style.color = '#0273E9';
                    }
                }
            },
            'dockedCallback': async (target) => {
                target.style.backgroundColor = 'transparent';
                target.style.borderBottomWidth = '0px';
                const button = target.querySelector('[data-consultation]');
                if (button) {
                    button.style.backgroundColor = 'white';
                    button.style.color = '#0273E9';
                }
            },
            'undockedCallback': async (target) => {
                target.style.backgroundColor = '#202528';
                target.style.borderBottomWidth = '1px';
                const button = target.querySelector('[data-consultation]');
                if (button) {
                    button.style.backgroundColor = '#0273E9';
                    button.style.color = 'white';
                }
            },
        });
        new Navigation({
            'idList': ['desktop-loading-dark', 'mobile-loading-dark'],
            'initialCallback': (target) => {
                if (window.scrollY !== 0) {
                    target.style.backgroundColor = '#202528';
                }
                else {
                    target.style.backgroundColor = 'transparent';
                }
            },
            'dockedCallback': async (target) => {
                target.style.backgroundColor = 'transparent';
            },
            'undockedCallback': async (target) => {
                target.style.backgroundColor = '#202528';
            },
        });
        new Navigation({
            'idList': ['desktop-light', 'mobile-light'],
            'initialCallback': (target) => {
                if (window.scrollY !== 0) {
                    target.style.backgroundColor = '#FFFFFF';
                    target.style.borderBottomWidth = '1px';
                }
                else {
                    target.style.backgroundColor = 'transparent';
                    target.style.borderBottomWidth = '0px';
                }
            },
            'dockedCallback': async (target) => {
                target.style.backgroundColor = 'transparent';
                target.style.borderBottomWidth = '0px';
            },
            'undockedCallback': async (target) => {
                target.style.backgroundColor = '#FFFFFF';
                target.style.borderBottomWidth = '1px';
            },
        });
        new Navigation({
            'idList': ['desktop-loading-light', 'mobile-loading-light'],
            'initialCallback': (target) => {
                if (window.scrollY !== 0) {
                    target.style.backgroundColor = '#FFFFFF';
                }
                else {
                    target.style.backgroundColor = 'transparent';
                }
            },
            'dockedCallback': async (target) => {
                target.style.backgroundColor = 'transparent';
            },
            'undockedCallback': async (target) => {
                target.style.backgroundColor = '#FFFFFF';
            },
        });
        const activeIndicator = document.querySelector('[data-activeindicator]');
        if (activeIndicator) {
            activeIndicator.style.top = '8px';
            activeIndicator.style.opacity = '0';
            activeIndicator.classList.add('active_indicator-transition');
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => {
                    activeIndicator.style.top = '0px';
                    activeIndicator.style.opacity = '1';
                }, 500);
            });
        }
        document.querySelectorAll('[data-pageprogress]').forEach(progressBar => {
            document.addEventListener("scroll", () => {
                let pageProgress = Math.round((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
                progressBar.style.width = `${pageProgress}%`;
            });
        });
        // Request a sample report scroll and focus.
        const ctaSampleReport = document.querySelector('.cta-sample_report');
        ctaSampleReport === null || ctaSampleReport === void 0 ? void 0 : ctaSampleReport.addEventListener('click', () => {
            const target = document.querySelector('.cta_input_target');
            target.focus();
            target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
    }
    static initialiseMisc() {
        const preloader = document.getElementById('preloader');
        window.addEventListener('DOMContentLoaded', async () => {
            await new Promise(resolve => setTimeout(resolve, 200));
            preloader.style.opacity = '0';
            await new Promise(resolve => setTimeout(resolve, 400));
            preloader.style.display = 'none';
        });
        // new Cookie();
        Animation.create([
            {
                'ids': ['fade-in'],
                'yTriggerPoint': 100,
                'duration': 800,
            },
        ]);
        const value = new BaseConverter('50', 6);
        const animationWave = new AnimationWave({
            'elementConfiguration': {
                'owasp_1': 'owasp_1_waveAnimation',
                'owasp_2': 'owasp_2_waveAnimation',
                'owasp_3': 'owasp_3_waveAnimation',
                'portswigger_icon': 'portswigger_icon_waveAnimation',
                'crest_1': 'crest_icon_waveAnimation',
                'crest_2': 'crest_icon_waveAnimation',
                'crest_3': 'crest_icon_waveAnimation'
            }
        });
    }
    static initialiseRequestAReport() {
        new Form({
            'id': '1',
            'route': '/data/report',
            'data': ['email'],
            'preRequestCallback': Router.preRequestCallbackRequestAReport,
            'successCallback': Router.successCallbackRequestAReport,
            'errorCallback': Router.errorCallbackRequestAReport,
        });
    }
    static preRequestCallbackRequestAReport(formData) {
        Router.errorMessage.innerText = '';
        Router.successMessage.innerText = '';
        const emailRegularExpression = /\S+@\S+\.\S+/;
        if (!(emailRegularExpression.test(String(formData.email).toLowerCase()))) {
            Router.errorMessage.innerText = 'Please provide a valid email address.';
            return false;
        }
        Router.pendingMessage.innerText = 'Please wait, your request is being sent...';
        return true;
    }
    static successCallbackRequestAReport() {
        Router.pendingMessage.innerText = '';
        window.dataLayer.push({ 'event': 'Form Submit - Report Request' });
        Router.successMessage.innerText = 'Thank you! Your request has been made.';
    }
    static errorCallbackRequestAReport(response) {
        Router.pendingMessage.innerText = '';
        Router.errorMessage.innerText = response.error;
    }
}
Router.errorMessage = document.querySelector('[data-form="error"]');
Router.successMessage = document.querySelector('[data-form="success"]');
Router.pendingMessage = document.querySelector('[data-form="pending"]');
;
