import Contact from '../class/Contact/Contact.js';
import Form from '../class/Form/Form.js';
export default class Router {
    static initialise() {
        Router.initialiseContact();
    }
    static initialiseContact() {
        new Contact(['first', 'last', 'email', 'company', 'subject', 'message']);
        const textArea = document.getElementsByTagName('textarea');
        for (let i = 0; i < textArea.length; i++) {
            textArea[i].style.height = `${textArea[i].scrollHeight}px`;
            textArea[i].addEventListener('input', OnInput, false);
        }
        function OnInput() {
            this.style.height = 'auto';
            this.style.height = (this.scrollHeight) + 'px';
        }
        new Form({
            'id': '0',
            'route': '/data/contact',
            'data': ['firstName', 'lastName', 'email', 'company', 'subject', 'message'],
            'preRequestCallback': Router.preRequestCallback,
            'successCallback': Router.successCallback,
            'errorCallback': Router.errorCallback,
        });
    }
    static preRequestCallback(formData) {
        // Router.errorMessage.innerText = '';
        // Router.successMessage.innerText = '';
        Router.populateMessage(Router.successMessage, '');
        Router.populateMessage(Router.errorMessage, '');
        const emailRegularExpression = /\S+@\S+\.\S+/;
        if ((String(formData.firstName) == null) || (String(formData.firstName) == '')) {
            // Router.errorMessage.innerText = 'Please provide a first name.';
            Router.populateMessage(Router.errorMessage, 'Please provide a first name.');
            return false;
        }
        if (!(emailRegularExpression.test(String(formData.email).toLowerCase()))) {
            // Router.errorMessage.innerText = 'Please provide a valid email address.';
            Router.populateMessage(Router.errorMessage, 'Please provide a valid email address.');
            return false;
        }
        // Router.pendingMessage.innerText = 'Please wait, your message is being sent...';
        Router.populateMessage(Router.pendingMessage, 'Please wait, your message is being sent...');
        return true;
    }
    static successCallback() {
        // Router.pendingMessage.innerText = '';
        Router.populateMessage(Router.pendingMessage, '');
        window.dataLayer.push({ 'event': 'Form Submit - Contect' });
        // Router.successMessage.innerText = 'Thank you! Your message has been sent.'
        Router.populateMessage(Router.successMessage, 'Thank you! Your message has been sent.');
    }
    static errorCallback(response) {
        // Router.pendingMessage.innerText = '';
        Router.populateMessage(Router.pendingMessage, '');
        // Router.errorMessage.innerText = response.error;
        Router.populateMessage(Router.errorMessage, response.error);
    }
    static populateMessage(element, message) {
        element.innerText = message;
        if (message == '') {
            element.classList.remove('cf-s-margin_top-xs5');
            return;
        }
        element.classList.add('cf-s-margin_top-xs5');
    }
}
Router.errorMessage = document.querySelector('[data-form="error"]');
Router.successMessage = document.querySelector('[data-form="success"]');
Router.pendingMessage = document.querySelector('[data-form="pending"]');
;
