import Animation from '../class/Animation/Animation.js';
import Form from '../class/Form/Form.js';
export default class Router {
    static initialise() {
        Animation.create([
            {
                'ids': ['fade-in-home_hero'],
                'yTriggerPoint': -1000,
                'duration': 800,
            },
        ]);
        Router.initialiseContact();
        // const emblaNode = document.querySelector('.embla__viewport') as HTMLElement;
        // const embla = EmblaCarousel(emblaNode, { 
        //     loop: false, 
        //     startIndex: 1, 
        //     align: 'center' 
        // });
    }
    ;
    static initialiseContact() {
        const textArea = document.getElementsByTagName('textarea');
        new Form({
            'id': '0',
            'route': '/data/hero_contact',
            'data': ['name', 'email', 'company', 'phone', 'message'],
            'preRequestCallback': Router.preRequestCallback,
            'successCallback': Router.successCallback,
            'errorCallback': Router.errorCallback,
        });
    }
    static preRequestCallback(formData) {
        // Router.errorMessage.innerText = '';
        // Router.successMessage.innerText = '';
        Router.populateMessage(Router.errorMessage, '');
        Router.populateMessage(Router.successMessage, '');
        const emailRegularExpression = /\S+@\S+\.\S+/;
        const phoneRegularExpression = /^\+?(\d[\d-.() ]?){7,14}\d$/;
        if ((String(formData.firstName) == null) || (String(formData.firstName) == '')) {
            // Router.errorMessage.innerText = 'Please provide a first name.';
            Router.populateMessage(Router.errorMessage, 'Please provide a name.');
            return false;
        }
        if (!(emailRegularExpression.test(String(formData.email).toLowerCase()))) {
            // Router.errorMessage.innerText = 'Please provide a valid email address.';
            Router.populateMessage(Router.errorMessage, 'Please provide a valid email address.');
            return false;
        }
        if (formData.phone && !(phoneRegularExpression.test(String(formData.phone)))) {
            Router.populateMessage(Router.errorMessage, 'Please provide a valid phone number.');
            return false;
        }
        // Router.pendingMessage.innerText = 'Please wait, your message is being sent...';
        Router.populateMessage(Router.pendingMessage, 'Please wait, your message is being sent...');
        return true;
    }
    static successCallback() {
        // Router.pendingMessage.innerText = '';
        Router.populateMessage(Router.pendingMessage, '');
        window.dataLayer.push({ 'event': 'Form Submit' });
        // Router.successMessage.innerText = 'Thank you! Your message has been sent.'
        Router.populateMessage(Router.successMessage, 'Thank you! Your message has been sent.');
    }
    static errorCallback(response) {
        // Router.pendingMessage.innerText = '';
        Router.populateMessage(Router.pendingMessage, '');
        // Router.errorMessage.innerText = response.error;
        Router.populateMessage(Router.errorMessage, response.error);
    }
    static populateMessage(element, message) {
        element.innerText = message;
        if (message == '') {
            element.classList.remove('cf-s-margin_top-xs5');
            return;
        }
        element.classList.add('cf-s-margin_top-xs5');
    }
}
Router.errorMessage = document.querySelector('[data-form="error"]');
Router.successMessage = document.querySelector('[data-form="success"]');
Router.pendingMessage = document.querySelector('[data-form="pending"]');
;
