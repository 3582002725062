export default class AnimationWave {
    constructor(configuration) {
        this.configuration = {};
        this.elementIntervalInSeconds = 0.1;
        this.waveIntervalInSeconds = 25;
        this.animationDurationInSeconds = 0.26;
        this.initialDelayInSeconds = 2;
        Object.keys(configuration.elementConfiguration).forEach((element) => {
            const selectedElement = document.querySelector(`.${element}`);
            this.configuration[element] = {
                classAnimation: configuration.elementConfiguration[element],
                element: selectedElement
            };
        });
        if (!(this._isConfigured()))
            return;
        setTimeout(() => this._startWave(), this.initialDelayInSeconds * 1000);
    }
    _isConfigured() {
        return Object.values(this.configuration).every((config) => {
            return config.element != null;
        });
    }
    _animate(index, elements, elementIntervalInMilliseconds, animationDurationInMilliseconds) {
        if (index >= elements.length) {
            setTimeout(() => this._startWave(), this.waveIntervalInSeconds * 1000);
            return;
        }
        const currentElement = this.configuration[elements[index]];
        currentElement.element.classList.add(currentElement.classAnimation);
        setTimeout(() => {
            currentElement.element.classList.remove(currentElement.classAnimation);
        }, animationDurationInMilliseconds);
        setTimeout(() => {
            this._animate(index + 1, elements, elementIntervalInMilliseconds, animationDurationInMilliseconds);
        }, elementIntervalInMilliseconds);
    }
    _startWave() {
        const elements = Object.keys(this.configuration);
        this._animate(0, elements, this.elementIntervalInSeconds * 1000, this.animationDurationInSeconds * 1000);
    }
}
