import global from './route/global.js';
import index from './route/index.js';
import contact from './route/contact.js';
import freeVulnerabilityAssessment from './route/free_vulnerability_assessment.js';
export default class Router {
    static initialise() {
        Router._route('*', global);
        Router._route('/', index);
        Router._route('/contact', contact);
        Router._route('/free-vulnerability-assessment', freeVulnerabilityAssessment);
    }
    ;
    static _route(context, Class) {
        if (context === window.location.pathname || context === '*')
            Class.initialise();
    }
    ;
}
;
Router.initialise();
